.auth {
  position: relative;
  /* width: 100%; */
  width: 1920px;
  height: 100vh;
  min-height: 1080px;
  background-color: #ffffff;
  margin: auto;
  text-align: center;
}
.kindred_colors {
  display: flex;
  width: 100%;
}

.kindred_logo {
  display: flex;
  margin: 100px auto;
  margin-bottom: 140px;
}

.group {
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.login_form {
  margin: auto;
  width: 460px;
}

.login_antd_form {
  display: flex;
  flex-flow: column;
}

.login_button,
.ant-btn {
  width: 460px;
  height: 70px;
  background-color: #00a5de;
  border-color: none !important;
  color: white;
  border-radius: 0px;
  margin-top: 50px;
  font-family: Factoria;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.96px;
  color: #ffffff;
}

.login_button:hover,
.login_button:focus,
.login_button:active {
  background-color: #00a5de !important;
  border-color: white;
  color: white;
}
.login_input {
  width: 458px;
  height: 61px;
  border: solid 1px rgba(0, 0, 0, 0.4);
  border-radius: 0px;
}
.login_name {
  width: 139px;
  height: 64px;
  font-family: Factoria;
  font-size: 48px;
  letter-spacing: 1.2px;
  color: #000000;
  margin-bottom: 50px;
}
