@font-face {
  src: url(assets/fonts/Factoria-Book.ttf);
  font-family: Factoria;
}

@font-face {
  src: url(assets/fonts/FactoriaW00Demi.ttf);
  font-family: Factoria-Demi;
}
@font-face {
  src: url(assets/fonts/Roboto-Regular.ttf);
  font-family: Roboto;
}
@font-face {
  src: url(assets/fonts/Roboto-Light.ttf);
  font-family: Roboto-Light;
}

.App {
  text-align: center;
  font-family: arial;
}

